
import Vue from 'vue'
import { myConfirm } from '@/utils/confirm'
import Ellipsis from '@/components/Ellipsis.vue'
import { ConvertStatus, checkModelConvertStatus } from '@/utils/check-model-convert'
import { downloadRawFile } from '@/utils/downloadFile'
import { Base64 } from '@/utils/base64'

export default Vue.extend({
  components: { Ellipsis },
  data() {
    return {
      editResourceModalVisible: false,
      selection: [],
    }
  },
  computed: {
    databaseList() {
      return this.$store.state.DatabaseModule.documentList.filter(v => v.uploadFile.fileName.includes(this.searchText)) || []
    },
    searchText() {
      return this.$store.state.DatabaseModule.searchText
    },
    hasPermission(): boolean {
      return this.$store.getters.isAdmin || this.$store.state.userInfo.userId === this.$store.getters['DatabaseModule/selectedCatologueCreatorId']
    },
  },
  methods: {
    handleSelect(val) {
      this.$store.commit('DatabaseModule/setSelection', val)
    },
    formatSize(size: number) {
      return `${(size / 1024 / 1024).toFixed(2)}MB`
    },
    handleDelete(id) {
      myConfirm(this, {
        text: '确认要删除此资料？',
        resolve: () => {
          this.$store.dispatch('DatabaseModule/deleteDocument', {
            id,
          })
        },
      })
    },
    handleDownload(data) {
      downloadRawFile({
        fileId: data.uploadFile.fileId,
        fileName: data.uploadFile.fileName + data.uploadFile.fileExtention,
      })
    },
    async checkFile(item) {
      const fileExtention = item.fileExtension
      if (['.rvt', '.dwg', '.ifc'].includes(fileExtention)) {
        const convertRes = await checkModelConvertStatus(item.uploadFile.fileId)
        if (convertRes) {
          // this.modelViewVisible = true;
          const { href } = this.$router.resolve({
            path: `/model-view?id=${item.uploadFile.fileId}&draw=${item.uploadFile?.fileExtention === '.dwg' ? 'true' : 'false'
              }&rightmenu=${item.uploadFile?.fileExtention === '.rvt' ? 'true' : 'false'}`,
          })
          window.open(href, '_blank')
        }
      }
      else {
        const isImg = ['.jpeg', '.jpg', '.png'].includes(item.uploadFile.fileExtention)
        window.open(
          `https://fileview.spdspd.com/onlinePreview?url=${encodeURIComponent(
            Base64.encode(
              `${`${process.env.VUE_APP_FILEPREVIEW
               }/`}${item.uploadFile?.fileUrl
              }?authorization=Bearer%20${this.$store.state.sessionId
              }&fullfilename=${item.uploadFile?.fileName}${item.uploadFile?.fileExtention}`,
            ),
          )}&watermarkTxt=${this.$store.state.userInfo.nickName}`,
        )
      }
    },
  },
})
