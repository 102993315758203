
import Vue from 'vue';
import LeftRightLayout from '@/layout/left-right-layout/left-right-layout.vue';
import TopBotLayout from '@/layout/top-bot-layout/top-bot-layout.vue';
import CatalogueHeader from '@/components/catalogue/catalogue-header.vue';
import CatalogueItem from '@/components/catalogue/catalogue-item.vue';
import CreateCatalogueModal from '@/components/catalogue/create-catalogue-modal.vue';
import DatabaseToolHeader from '@/features/database/database-tool-header.vue';
import DatabaseTable from '@/features/database/database-table.vue';

export default Vue.extend({
  components: {
    LeftRightLayout,
    CatalogueHeader,
    CatalogueItem,
    CreateCatalogueModal,
    TopBotLayout,
    DatabaseTable,
    DatabaseToolHeader,
  },
  created() {
    this.$store.commit('breadcrumbList', [
      {
        name: '资料库',
        path: 'database',
      },
    ]);
    this.$store.dispatch('DatabaseModule/init');
  },
  computed: {
    editCatalogueName() {
      return this.$store.getters['DatabaseModule/editCatalogueName'];
    },
    catalogueList(): any[] {
      return this.sortList(this.$store.state.DatabaseModule.catalogueList);
    },
  },
  data() {
    return {
      createCatalogueModalVisible: false,
    };
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员') {
          return -1;
        }
        return 0;
      });
    },
    createCatalogue() {
      this.$store.commit('DatabaseModule/setEditCatalogue', {});
      this.createCatalogueModalVisible = true;
    },
    createOrEditCatalogueConfirm(inputValue) {
      this.$store.dispatch(
        'DatabaseModule/createEditCatalogue',
        {
          name: inputValue,
          id: this.$store.state.DatabaseModule.editCatalogue?.id,
        },
      );
    },
    isSelected(id) {
      return id === this.$store.getters['DatabaseModule/selectedCatalogueId'];
    },
    isCreateBySelfOrAdmin(createUserId) {
      return createUserId === this.$store.state.userInfo.userId || this.$store.getters.isAdmin;
    },
    selectCatalogue(item) {
      this.$store.commit('DatabaseModule/setSelectedCatalogue', item);
      this.$store.dispatch('DatabaseModule/getDocuments', {
        catalogeId: item.id,
      });
    },
    editCatalogue(item) {
      this.$store.commit('DatabaseModule/setEditCatalogue', item);
      this.createCatalogueModalVisible = true;
    },
    deleteCatalogue(item) {
      this.$store.dispatch(
        'DatabaseModule/deleteCatalogue',
        { id: item.id },
      );
    },
  },
});
