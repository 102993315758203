
import Vue from 'vue';
import SearchInput from '@/components/SearchInput.vue';
import { myConfirm } from '@/utils/confirm';
import { ibuidingAPI } from '@/api/ibuidingAPI';
import DatabaseUploadModal from './database-upload-modal.vue';

export default Vue.extend({
  components: { SearchInput, DatabaseUploadModal },
  data() {
    return {
      category: '全部',
      categoryOptions: [{ value: '全部', label: '全部' }],
      createModalVisible: false,
    };
  },
  computed: {
    selectedCatalogueId() {
      return this.$store.getters['DatabaseModule/selectedCatalogueId'];
    },
    hasPermission(): boolean {
      return this.$store.getters.isAdmin || this.$store.state.userInfo.userId === this.$store.getters['DatabaseModule/selectedCatologueCreatorId'];
    },
    hasSelectedCatalogue(): boolean {
      return (
        this.$store.getters['DatabaseModule/selectedCatalogueId'] !== undefined
      );
    },
    selection() {
      return this.$store.state.DatabaseModule.selection;
    },

  },
  methods: {
    handleBatchOptionClick(e) {
      switch (e) {
        case 0:
          if (this.selection.length === 0) {
            this.$message.warning('请选择需要操作的文件');
            return;
          }
          myConfirm(this, {
            text: '确定要删除选中的文件吗？',
            resolve: async () => {
              const promises: any[] = [];
              this.selection.forEach((v) => {
                promises.push(ibuidingAPI.vAStudyDocumentFilesRemoveCreate({ id: v.documentFile.id }));
              });
              await Promise.all(promises);
              this.$store.dispatch('DatabaseModule/getDocuments', { catalogeId: this.selectedCatalogueId });
            },
          });
          break;
        default: break;
      }
    },
    searchInputChange(value) {
      this.$store.commit('DatabaseModule/setSearchText', value);
    },
    createClick() {
      this.$store.commit('DatabaseModule/resetEditDatabase');
      this.createModalVisible = true;
    },
  },
});
