import { downloadFile } from '@/api/Jobs';
import store from '@/store';

export async function downloadRawFile({ fileId, fileName }: { fileId: string; fileName: string }) {
  const res: any = await downloadFile(
    { fileId: fileId! },
    store.state.sessionId,
  );
  const blob = new Blob([res], { type: 'application/octet-stream' });
  const reader = new FileReader();
  reader.onload = function (e) {
    const a = document.createElement('a');
    a.download = decodeURIComponent(fileName!);
    a.href = e.target!.result as string;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  reader.readAsDataURL(blob);
}
