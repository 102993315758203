
import { convertModel } from '@/api/model-view';
import { uploadFile } from '@/utils/upload-file';
import { Message } from 'element-ui';
import Vue from 'vue';

const extensions = [
  '.rvt',
  '.stl',
  '.obj',
  '.rfa',
  '.fbx',
  '.rft',
  '.dgn',
  '.sat',
  '.skp',
  '.3ds',
  '.3mf',
  '.dae',
  '.gim',
  '.ifc',
  '.dxf',
  '.dwg',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.txt'];

export default Vue.extend({
  name: 'CreateResourceModal',
  props: {
    visible: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      fileList: [] as any[],
      extensions,
    };
  },
  computed: {
    selectedCatalogueId(): any {
      return this.$store.getters['DatabaseModule/selectedCatalogueId'];
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
      this.fileList = [];
    },
    deleteFile(file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
    },
    handleUploadFile(_file) {
      const fileRaw = _file.raw;
      const index = fileRaw.name.lastIndexOf('.');
      const type: string = fileRaw.name.slice(index);
      if (type && extensions.indexOf(type) === -1) {
        Message.error('只能上传规定格式文件');
        return;
      }
      const file = {
        fileName: _file.name.slice(0, index),
        fileId: '',
        status: 0,
        fileExtention: type,
        fileSize: _file.size,
        uid: _file.uid,
      };
      this.fileList.push(file);
      const setFile = async (data) => {
        if (data.fileId) {
          this.fileList = this.fileList.map((v) => {
            if (v.uid === file.uid) {
              v.fileId = data.fileId;
              v.status = 1;
            }
            return v;
          });
        }
        if (data.percentage === '100') {
          this.fileList = this.fileList.map((v) => {
            if (v.uid === file.uid) {
              v.status = 2;
              v.fileUrl = data.filePath;
            }
            return v;
          });
          if (type === '.rvt') {
            convertModel(this.fileList.find((v) => v.uid === file.uid).fileId);
          }
        }
      };
      uploadFile(fileRaw, setFile, this.$store.state.sessionId);
    },
    async confirmCreateOrEdit() {
      if (this.fileList.length === 0) {
        Message.error('请上传文件');
        return;
      }
      if (this.fileList.some((v) => v.status !== 2)) {
        Message.error('文件上传中，请稍后');
        return;
      }
      this.fileList.forEach((v) => {
        const params = { ...v, typeId: this.selectedCatalogueId };
        this.$store.dispatch('DatabaseModule/saveDocuments', params);
      });
      this.$emit('update:visible', false);
      this.fileList = [];
    },

  },
  watch: {
    visible: {
      handler(val) {
        this.fileList = [];
      },
    },
  },
});
